import StringTool from '../common/stringTool'
import Cache from '../../libs/common/cache.js'
import Log from '../common/log'
import AES from '../common/AES'
import fly from 'flyio'
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";

const urlConfig = window.urlConfig;
const wsRoot = urlConfig.wsRoot;
const printPreviewRoot = urlConfig.printPreviewRoot;
const printEggPreviewRoot = urlConfig.printEggPreviewRoot;
const urlRoot = urlConfig.urlRoot;
const appId = '00000050'
const channel = '50'

const post = (url, data) => {
  return new Promise((resolve, reject) => {
    const link = urlRoot.concat(url)
    const reqData = {
      appId: appId,
      channel: channel,
      serial: StringTool.getNowFormatDate() + StringTool.getNowFormatTime() + StringTool.getRandomNum(1000, 9999)
    }
    const key = 'oNpnVlKURr4an2Y8'
    if (data) {
      reqData['data'] = AES.ECB_encrypt(JSON.stringify(data), key)
    }
    const header = {
      'Content-Type': 'application/json',
      "X-Sign": StringTool.md5(reqData['serial'] + reqData['serial'].substr(reqData['serial'].length - 2, 2) + appId)
    }
    if(Cache.local.get('isRevenue') === 'Y'){
      header['X-IsFake'] = 'Y'
    }
    if(Cache.local.get('token')){
      header['X-Token'] = Cache.local.get('token')
    }
    Log.normal('url: ' + link)
    Log.normal('data: ' + JSON.stringify(reqData))
    if (reqData['data']) {
      const orgin = AES.ECB_decrypt(reqData['data'], key)
      Log.normal('请求解密结果：' + orgin)
    }
    fly
      .post(
        link,
        reqData,
        {
          headers: header
        }
      )
      .then(function (res) {
        if (res.headers.exchange) {
          Cache.local.set('token', res.headers.exchange)
        }
        if (validResponse(res)) {
          if (res['data']['data']) {
            const resData = AES.ECB_decrypt(res['data']['data'], key)
            Log.normal('接口code:200返回解密结果：' + resData)
            resolve(JSON.parse(resData));
          } else {
            resolve({});
          }
        } else {
          ipc.invoke(ipcApiRoute.exportLog, link).then(r => {})
          const requestData = AES.ECB_decrypt(reqData['data'] || {}, key)
          ipc.invoke(ipcApiRoute.exportLog, requestData).then(r => {})
          if (res && res['data'] && res['data']['msg']) {
            Log.normal(JSON.stringify(res['data']))
            ipc.invoke(ipcApiRoute.exportLog, res['data']).then(r => {})
            reject(res['data']['msg'])
          } else {
            reject('网络异常')
          }
        }
      })
      .catch(function (err) {
        ipc.invoke(ipcApiRoute.exportLog, link).then(r => {})
        const requestData = AES.ECB_decrypt(reqData['data'] || {}, key)
        ipc.invoke(ipcApiRoute.exportLog, requestData).then(r => {})
        ipc.invoke(ipcApiRoute.exportLog, err).then(r => {})
        if (err.status === 408) {
          reject('登录超时，请重新登录')
        } else {
          if(err.message === "Network Error"){
            reject("请检查网络状态")
          }else{
            reject(err.message)
          }
        }
      })
  })
}

const get = (url, _success, _error) => {
  console.log(url)
  fly.get(url)
    .then(function (res) {
      console.log(res)
      _success(res.data)
    })
    .catch(function (err) {
      console.log(err)
      _error(err)
    })
}

const postC = (url, data) => {
  return new Promise((resolve, reject) => {
    const link = urlRoot.concat(url)
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    if(Cache.local.get('token')){
      header['X-Token'] = Cache.local.get('token')
    }
    fly
      .post(
        link,
        data,
        {
          headers: header
        }
      )
      .then(function (res) {
        if (res['data']['data']) {
          let resData = res['data']['data'];
          Log.normal('接口code:200返回解密结果：' + resData)
          if(typeof(resData) == "string") {
            resData = JSON.parse(resData);
          }
          resolve(resData);
        } else {
          resolve({});
        }
      })
      .catch(function (err) {
        reject(err.message)
      })
  })
}

function validResponse(response) {
  const SUCCESS = '200'
  return response && response['data'] && response['data']['code'] === SUCCESS
}

export default {
  post,
  get,
  postC,
  urlRoot,
  wsRoot,
  printPreviewRoot,
  printEggPreviewRoot,
}
